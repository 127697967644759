export default class TrackingService {

    constructor() {
        /*                                    */
        this.tracker = window.o_tracking || {};
        if (!this.tracker.bct) {
            this.tracker.bct = {
                sendMergeToTrackingServer: () => {
                },
                sendEventToTrackingServer: () => {
                },
                submitMove: () => {
                }
            }
        }

        /*                                    */
        this.global = window.o_global
        this.global = window.o_global || {};
        if (!this.global.eventQBus) {
            this.global.eventQBus = {
                emit: () => {
                }
            }
        }

        /*                                    */
        this.util = window.o_util || {};
        if (!this.util.o_util) {
            this.util.o_util = {
                misc: {
                    getPagecluster: () => {
                    }
                }
            }
        }
    }

    sendClickTrackingEvent(promoType, featureOrder, featureIndex, filledSlots, position, tile, masFeatureTrackingLabels) {
        const clickTrackingInfo = this._buildClickTrackingPayload(promoType, tile.destinationUrl, featureOrder, featureIndex, filledSlots, position, tile.sourceUrl);

        const featureTrackingData = {
            name: "click",
            features: [
                this._createContainerLabel(featureOrder, featureIndex, filledSlots, "clicked", promoType, masFeatureTrackingLabels),
                this._createTileLabel(position, featureOrder, tile.destinationUrl, tile.sourceUrl, tile.imageId, "clicked", promoType),
            ]
        };
        this.global.eventQBus.emit('tracking.bct.submitMoveAction', clickTrackingInfo, featureTrackingData);
        this.global.eventQBus.emit("tracking.bct.trackOnNextPageImpression", {"promo_Click": promoType});
    }

    sendClickTrackingEventForPopup(promoType, featureOrder, featureIndex, filledSlots, position, tile, masFeatureTrackingLabels) {
        const clickTrackingInfo = this._buildClickTrackingPayload(promoType, tile.destinationUrl, featureOrder, featureIndex, filledSlots, position, tile.sourceUrl);
        const featureTrackingData = {
            name: "open",
            features: [
                this._createContainerLabel(featureOrder, featureIndex, filledSlots, "clicked", promoType, masFeatureTrackingLabels),
                this._createTileLabel(position, featureOrder, tile.destinationUrl, tile.sourceUrl, tile.imageId, "clicked", promoType),
            ]
        }
        this.global.eventQBus.emit('tracking.bct.submitAction', clickTrackingInfo, featureTrackingData);
    }

    sendLoadedTrackingEvent(promoType, featureOrder, featureIndex, filledSlots, tiles, masFeatureTrackingLabels) {
        let trackingData = [this._createContainerLabel(featureOrder, featureIndex, filledSlots, "loaded", promoType, masFeatureTrackingLabels)];
        tiles.forEach(tile => {
            if (tile && Object.keys(tile).length !== 0) {
                const tileLabel = this._createTileLabel(tile.position, featureOrder, tile.destinationUrl, tile.sourceUrl, tile.imageId, "loaded", promoType);
                trackingData.push(tileLabel);
            }
        })

        this.global.eventQBus.emit('tracking.bct.addFeaturesToPageImpression', trackingData);
    }

    sendScrollTrackingEvent(promoType, featureOrder, tilePositions) {
        let parentFeature = {'featureId': this._createFeatureId(promoType, featureOrder)}
        let tileFeatures = tilePositions.map(position => {
            return {'featureId': this._createChildFeatureId(promoType, featureOrder, position)}
        })
        this.global.eventQBus.emit('tracking.bct.submitMiniActions', [parentFeature, ...tileFeatures]);
    }

    _createContainerLabel(featureOrder, featureIndex, filledSlots, status, promoType, masFeatureTrackingLabels) {
        const staticPromoFeatureLabels = {
            promo_Feature: ["true"],
            promo_FeatureNumber: [(featureIndex + 1).toString()]
        };
        const labels = {
            ...masFeatureTrackingLabels,
            ...staticPromoFeatureLabels
        }
        let containerTrackingLabel = {
            id: this._createFeatureId(promoType, featureOrder),
            name: promoType,
            position: featureOrder,
            status: status,
            labels: labels
        };
        if (promoType === "StaticPromotion") {
            containerTrackingLabel.labels["promo_FilledSlots"] = [filledSlots.toString()]
        }
        return containerTrackingLabel
    }

    _createTileLabel(position, featureOrder, urlPath, source, imageId, status, promoType) {
        /*                                                                                                                                 */
        urlPath = (urlPath) ? urlPath : "n/a"
        source = (source) ? source : "n/a"
        imageId = (imageId) ? imageId : "n/a"

        let labels = {
            promo_Content: [urlPath],
            promo_Source: [source],
            promo_ImageId: [imageId]
        };
        if (urlPath.indexOf("selektion=") >= 0) {
            labels.promo_TargetPsr = [urlPath.split("selektion=")[1].split("&")[0]];
        }
        return {
            id: this._createChildFeatureId(promoType, featureOrder, position),
            parentId: this._createFeatureId(promoType, featureOrder),
            name: promoType + "-Teaser",
            position: position,
            status: status,
            labels: labels
        }
    }

    _createFeatureId(promoType, featureOrder) {
        return this._getIdPrefix(promoType) + featureOrder;
    }

    _createChildFeatureId(promoType, featureOrder, position) {
        return this._getIdPrefix(promoType) + featureOrder + "_teaser" + position;
    }

    _getIdPrefix(promoType) {
        switch (promoType) {
            case "StaticPromotion" :
                return "ft3_sp"
            case "ImageBanner":
                return "ft3_ib"
            default:
                return "unknown"
        }
    }

    _buildClickTrackingPayload(promoType, destination, featureOrder, featureIndex, filledSlots, position, source) {
        const clickTrackingInfo = {};
        let pageCluster = this.util.misc.getPagecluster();
        clickTrackingInfo["wk.promo_AttributionFeature"] = `${promoType}_${pageCluster}`;
        clickTrackingInfo["wk.promo_AttributionSource"] = (source) ? source : promoType;
        return clickTrackingInfo;
    }

}
